import React from "react"
import Css from "./ImageBitmap.module.css"

const ImageBitmap = props => {
    if(props.bitmap !== '') {
        return (
            <div className={Css.ImageBitmapContent}>
                <div className={Css.Text}>{props.text}</div>
                <img className={Css.Bitmap} src={require('../../images/' + props.bitmap)} alt={props.text} />
                <img className={Css.ImageWithBitmap} src={require('../../images/' + props.image)} alt={props.text} />
            </div>
        )
    } else {
        return (
            <div>
                <img className={Css.Image} src={require('../../images/' + props.image)} alt={props.text} />
                <div className={Css.Text}>{props.text}</div>
            </div>
        )
    }
}

export default ImageBitmap