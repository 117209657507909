import React from "react"
import GenericHero from "../../components/GenericHero"
import SiteHeader from "../../components/SiteHeader"
import TopImageContainer from "../../components/TopImageContainer"
import TopImage from "../../images/Service-hero-2.jpg"
import SiteFooter from "../../components/SiteFooter"
import LetsCoCreateSection from "../../components/LetsCoCreateSection"
import content from "../../data/Content"
import Seo from "../../components/Seo"

import InsightsCardsSection from "../../components/Insights/InsightsCardsSection"

export default function Insights() {
  let page = "insights"
  let data = content(page)
  return (
    <div className="content">
      <Seo page={content(page)} />
      <TopImageContainer image={TopImage}>
        <SiteHeader page={page} content={content("header")} />
        <GenericHero content={data} />
      </TopImageContainer>
      <InsightsCardsSection content={data} />
      <LetsCoCreateSection content={content("lets-co-create-section")} />
      <SiteFooter content={content("footer")} />
    </div>
  )
}
