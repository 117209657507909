import React from "react"
import Card from "../shared/Card/Card"
import Css from "./InsightsCardsSection.module.css"
import ImageBitmap from "./ImageBitmap"
import { Link } from "gatsby"


function stringToSlug (str) {
  str = str.replace(/^\s+|\s+$/g, '');
  str = str.toLowerCase();

  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

  return str;
}

const InsightsCardsSection = props => {
  
  let items = []
  let data = props.content["cards-section"]
  if (data && data["cards"]) {
      
    items = data["cards"].map((item, idx) => {

        return <div key={idx} className={`${Css.CardItem} ${item['wide'] ? Css.CardItemWide : ""}`} >
            <Card blueContainerStyle={item['wide'] ? "fullHalfWidth" : "halfWidth"}>
                {() => 
                <Link to={`/${item["action-url"]}/${stringToSlug(item["catch-phrase"])}`} >
                  <div className={Css.PhraseContainer}>
                      {
                        item['image'] !== '' || item['bitmap'] !== '' 
                        ? <ImageBitmap image={item["image"]} bitmap={item["bitmap"]} text={item["catch-phrase"]} /> 
                        : <div className={Css.Phrase}>{item["catch-phrase"]}</div>
                      }
                      
                          <p className={`${Css.PhraseAction} ${item['bitmap'] !== '' ? Css.PhraseActionBitmap : ""}`}>
                            {item["action"]} &gt;&gt;
                          </p>
                  </div>
                </Link>
                }
            </Card>
        </div>
    })
  }

  return (
    <div className={Css.InsightsCardsSection}  >
      {data["cards"] &&
        <div className={Css.CardsContainer} >{items}</div>
      }
    </div>
  )
}

export default InsightsCardsSection